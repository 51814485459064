import dayjs from "dayjs";
import { AgencyCommissionFix, AgencyCommissionRange, ContactPersonType, Contract, ContractWithBonusType, PaymentDetailsType, SystemType, WithContractType, WithoutContractType } from "../types";
import { initialPaymentDetails } from "../../../context/ClientCreateContext";
import { generateId } from "../../../utils/helpers";

export const backendPaymentDetails = (paymentDetails: PaymentDetailsType) => {
    switch (true) {
        case paymentDetails.type.includes("Payoneer to"):
            return {
                contract_currency: paymentDetails.contractCurrency,
                name: paymentDetails.type,
                receiver_name: paymentDetails.receiverCompanyName,
                email: paymentDetails.email,
                passport_copy_link: paymentDetails.passportScanUrl,
                reference_type: paymentDetails.fileType,
                is_checked: paymentDetails.statementFactChecked,
                payoneer_confirmation_link: paymentDetails.payoneer_confirmation_link,
                payoneer_letter_link: paymentDetails.payoneer_letter_link,
                bank_confirmation_link: paymentDetails.bank_confirmation_link,
                address_link: paymentDetails.address_link,
            };
        case paymentDetails.type.includes("SWIFT from"):
        case paymentDetails.type.includes("Local from"):
            return {
                contract_currency: paymentDetails.contractCurrency,
                name: paymentDetails.type,
                account_type: paymentDetails.bankCredentials?.accountType,
                bank_country: paymentDetails.bankCredentials?.receiverBankCountry,
                bank_name: paymentDetails.bankCredentials?.receiverBankName,
                swift_bic: paymentDetails.bankCredentials?.receiverBankSwift,
                receiver_name: paymentDetails.bankCredentials?.receiverCompanyName,
                account_number: paymentDetails.bankCredentials?.accountNumber,
                ach_number: paymentDetails.bankCredentials?.ACHRoutingNumber,
                receiver_country: paymentDetails.receiverAddress?.country,
                receiver_state: paymentDetails.receiverAddress?.region,
                receiver_city: paymentDetails.receiverAddress?.city,
                receiver_address: paymentDetails.receiverAddress?.address,
                receiver_zipcode: paymentDetails.receiverAddress?.postalCode,
                receiver_details: {
                    statutory_document: paymentDetails.receiverAddress?.bankSpravkaUrl,
                    director: {
                        full_name: paymentDetails.director?.fullName || "-",
                        country: paymentDetails.director?.citizenship || "-",
                        passport_copy_link: paymentDetails.director?.passportScanUrl || "-",
                    },
                    founders:
                        paymentDetails.founders &&
                        paymentDetails.founders?.map((founder) => ({
                            full_name: founder.fullName || "-",
                            country: founder.citizenship || "-",
                            passport_copy_link: founder.passportScanUrl || "-",
                        })),
                    bank_reference: paymentDetails.receiverAddress?.bankSpravkaUrl,
                    receiver: {
                        country: paymentDetails.receiverCitizenship?.citizenship,
                        passport_copy_link: paymentDetails.receiverCitizenship?.passportScanUrl,
                    },
                },
                is_checked: paymentDetails.statementFactChecked,
            };
        case paymentDetails.type.includes("USDT"):
        case paymentDetails.type.includes("WMZ"):
            return {
                contract_currency: paymentDetails.contractCurrency,
                name: paymentDetails.type,
                account_number: paymentDetails.accountNumberWMZorUSDT,
            };
        default:
            return {};
    }
};

export const frontendPaymentDetails = (data: any): PaymentDetailsType => {
    switch (true) {
        case data.name.includes("Payoneer to"):
            return {
                startDate: data.start_date && dayjs(data.start_date, "YYYY-MM-DD"),
                contractCurrency: data.contract_currency || "",
                type: data.name || "",
                receiverCompanyName: data.receiver_name || "",
                email: data.email || "",
                passportScanUrl: data.passport_copy_link || "",
                fileType: data.reference_type || "",
                receiverAddress: {
                    country: "",
                    region: "",
                    city: "",
                    address: "",
                    postalCode: "",
                    bankSpravkaUrl: "",
                },
                statementFactChecked: data.is_checked,
                payoneer_letter_link: data.payoneer_letter_link || "",
                payoneer_confirmation_link: data.payoneer_confirmation_link || "",
                bank_confirmation_link: data.bank_confirmation_link || "",
                address_link: data.address_link,
            };
        case data.name.includes("SWIFT from"):
        case data.name.includes("Local from"):
            return {
                startDate: data.start_date && dayjs(data.start_date, "YYYY-MM-DD"),
                contractCurrency: data.contract_currency || "",
                type: data.name || "",
                bankCredentials: {
                    receiverBankCountry: data.bank_country || "",
                    receiverBankName: data.bank_name || "",
                    receiverBankSwift: data.swift_bic || "",
                    receiverCompanyName: data.receiver_name || "",
                    accountNumber: data.account_number || "",
                    ACHRoutingNumber: data.ach_number || "",
                    accountType: data.account_type || "",
                },
                receiverAddress: {
                    country: data.receiver_country || "",
                    region: data.receiver_state || "",
                    city: data.receiver_city || "",
                    address: data.receiver_address || "",
                    postalCode: data.receiver_zipcode || "",
                    bankSpravkaUrl: data.receiver_details?.statutory_document || "",
                },
                director: {
                    fullName: data.receiver_details?.director.full_name || "",
                    citizenship: data.receiver_details?.director.country || "",
                    passportScanUrl: data.receiver_details?.director.passport_copy_link || "",
                },
                founders:
                    data.receiver_details?.founders &&
                    data.receiver_details?.founders.map((item: any) => ({
                        fieldId: generateId(),
                        fullName: item.full_name || "",
                        citizenship: item.country || "",
                        passportScanUrl: item.passport_copy_link || "",
                    })),
                receiverCitizenship: {
                    citizenship: data.receiver_details?.receiver.country || "",
                    passportScanUrl: data.receiver_details?.receiver.passport_copy_link || "",
                },
                statementFactChecked: data.is_checked || false,
            };
        case data.name.includes("USDT"):
        case data.name.includes("WMZ"):
            return {
                startDate: data.start_date && dayjs(data.start_date, "YYYY-MM-DD"),
                contractCurrency: data.contract_currency || "",
                type: data.name || "",
                accountNumberWMZorUSDT: data.account_number || "",
            };
        default:
            return initialPaymentDetails;
    }
};
const changeContactFieldNames = (contact: ContactPersonType) => ({
    full_name: contact.fullName,
    email: contact.email,
    position: contact.position,
    tg_login: contact.telegramLogin,
    tg_link: contact.telegramGroupUrl,
    phone_number: contact.phoneNumber,
    main_contact: contact.mainContact,
    notes: contact.note,
    tg_chat_id: contact.chatId,
});
export const backendContacts = (contactPeople: ContactPersonType[] | ContactPersonType) => {
    if (Array.isArray(contactPeople)) {
        return contactPeople.length && contactPeople.map((contact: ContactPersonType) => changeContactFieldNames(contact));
    }
    return changeContactFieldNames(contactPeople);
};
export const frontendContacts = (data: any) =>
    data.map((contact: any) => ({
        fullName: contact.full_name,
        email: contact.email,
        position: contact.position,
        telegramLogin: contact.tg_login,
        telegramGroupUrl: contact.tg_link,
        phoneNumber: contact.phone_number,
        mainContact: contact.main_contact,
        note: contact.notes,
        chatId: contact.tg_chat_id,
    }));
export const backendContract = (contract: Contract, currency: string) => ({
    llp: (contract as WithContractType).ourEntityName,
    client_type: contract.clientContractType,
    type: (contract as WithContractType).contractType,
    number_of_contract: (contract as WithContractType).contractNumber,
    contract_link: (contract as WithContractType).contractUrl,
    is_vat_payer: (contract as WithContractType).isVatPayer,
    contract_date:
        contract.clientContractType === "With contract"
            ? (contract as WithContractType).contractDate && dayjs((contract as WithContractType).contractDate).format("YYYY-MM-DD")
            : (contract as ContractWithBonusType).contractDate && dayjs((contract as ContractWithBonusType).contractDate).format("YYYY-MM-DD"),
    start_date: contract.startContractDate && dayjs(contract.startContractDate).format("YYYY-MM-DD"),
    end_date: null,
    contract_currency: currency,
    fee_currency: (contract as WithContractType).transferCommissionCurrency,
    fee_type: (contract as WithContractType).transferCommissionType,
    fee_value: (contract as WithoutContractType).commissionAmount,
});

export const frontendContract = (data: any) => ({
    ourEntityName: data.llp,
    clientContractType: data.client_type,
    contractType: data.type,
    contractNumber: data.number_of_contract,
    contractUrl: data.contract_link,
    isVatPayer: data.is_vat_payer,
    contractDate: data.contract_date,
    startContractDate: data.start_date,
    contractCurrency: data.contract_currency,
    transferCommissionCurrency: data.fee_currency,
    transferCommissionType: data.fee_type,
    commissionAmount: data.fee_value,
});

const changeSystemFieldNames = (system: SystemType) => ({
    ad_system: system.systemName,
    recipient_money: system.moneyReceiver,
    commission_type: system.typeOfAgencyCommission,
    agency_commission:
        system.typeOfAgencyCommission === "Fix"
            ? (system as AgencyCommissionFix).agencyCommissionAmount
            : (system as AgencyCommissionRange).ranges &&
              (system as AgencyCommissionRange).ranges.map((range) => ({
                  range_from: range.from,
                  range_to: range.to,
                  agency_commission: range.agencyCommissionAmount,
              })),
    is_bonus: system.isBonus === "Yes",
    date_from: system.date_from && dayjs(system.date_from).format("YYYY-MM-DD"),
    ...(system.moneyReceiver === "Mixed" && {
        we_part: system.we_part || 0,
        we_client: system.client_part || 0,
    }),
});

export const backendSystems = (systems: SystemType[] | SystemType) => {
    if (Array.isArray(systems)) {
        return systems && systems.length > 0 && systems.map((system) => changeSystemFieldNames(system));
    }
    return changeSystemFieldNames(systems);
};
export const frontendSystems = (data: any) => ({
    fieldId: generateId(),
    isBonus: data.is_bonus ? "Yes" : "No",
    date_from: (data.date_from && dayjs(data.date_from, "YYYY-MM-DD")) || null,
    systemName: data.ad_system,
    moneyReceiver: data.recipient_money,
    typeOfAgencyCommission: data.commission_type,
    agencyCommissionAmount: data.agency_commission,
    ranges:
        data.commission_type !== "Fix" &&
        data.agency_commission.length > 0 &&
        data.agency_commission.map((item: any) => ({
            fieldId: generateId(),
            from: item.range_from,
            to: item.range_to,
            agencyCommissionAmount: item.agency_commission,
        })),
    ...(data.recipient_money === "Mixed" && {
        we_part: data.we_part || 0,
        we_client: data.client_part || 0,
    }),
});

export {};
