import {useContext, useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import {TopBarContext} from "../../context/TopBarContext";
import CustomButton from "../../UI/Buttons/CustomButton";
import {AuthContext} from "../../context/AuthContext";
import {
    checkSystemConnectionWithBonusAcc,
    createInvoicesAll,
    createInvoicesOnlyKz,
    createInvoicesOnlyNotKz, prepareInvoicesAll,
    prepareInvoicesOnlyKz,
    prepareInvoicesOnlyNotKz, pullOutData
} from "./fetchers";
import {getSystemList} from "../ClientsPage/services/fetchers";
import SelectField from "../../UI/InputFields/SelectField";

const Functions = () => {
    const { setNotification } = useContext(AuthContext);
    const { handleChangePath, handleSetActions, handleChangeTabs } = useContext(TopBarContext);
    const [systemList, setSystemList] = useState([]);
    const [selectedSystems, setSelectedSystems] = useState({
        current: "",
        last: ""
    });
    const [errors, setErrors] = useState({
        current: {message: ""},
        last: {message: ""}
    });

    useEffect(() => {
        handleChangePath(["functions"]);
        handleSetActions([]);
        handleChangeTabs([]);
    }, []);

    useEffect(() => {
        (async () => {
            const res = await getSystemList();
            setSystemList(res);
        })()
    }, [])

    const handleInvoiceRequest = async (postFunction: Function) => {
        try {
            await postFunction();
            setNotification({
                open: true,
                type: "success",
                message: "Действие успешно запущено!"
            })
        } catch (err) {
            setNotification({
                open: true,
                type: "error",
                message: "Произошла ошибка попробуйте позднее!"
            })
        }
    }

    const handleDataRequest = async (type: "current" | "last") => {
        if (selectedSystems[type]) {
            try {
                await pullOutData(`pull_out/${type}/?system_name=${selectedSystems[type]}`);
                setErrors({
                    current: {message: ""},
                    last: {message: ""}
                })
                setNotification({
                    open: true,
                    type: "success",
                    message: "Действие успешно запущено!"
                })
            } catch (err) {
                setNotification({
                    open: true,
                    type: "error",
                    message: "Произошла ошибка попробуйте позднее!"
                })
            }
        } else {
            setErrors({
                ...errors,
                [type]: {
                    message: "Выберите систему"
                }
            })
        }
    }

    const handleChangeSystem = (type: "current" | "last", value: string) => {
        setSelectedSystems({
            ...selectedSystems,
            [type]: value
        })
    }

    return (
        <ContentContainer>
            <Box
                sx={{
                    p: "3rem",
                    background: "#fff",
                    width: "fit-content",
                    maxWidth: "740px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    minWidth: {xs: "100%", md: "736px"}
                }}
            >
                <Typography sx={{fontSize: "1.25rem", fontWeight: "500"}}>
                    Создать предварительные инвойсы:
                </Typography>
                <CustomButton style={{width: "fit-content"}} label="Создать предварительные инвойсы для КЗ" onChange={() => handleInvoiceRequest(prepareInvoicesOnlyKz)}/>
                <CustomButton style={{width: "fit-content"}} label="Создать предварительные инвойсы для НЕ КЗ" onChange={() => handleInvoiceRequest(prepareInvoicesOnlyNotKz)}/>
                <CustomButton style={{width: "fit-content"}} label="Создать предварительные инвойсы для ВСЕХ" onChange={() => handleInvoiceRequest(prepareInvoicesAll)}/>
                <Typography sx={{fontSize: "1.25rem", fontWeight: "500"}}>
                    Создать инвойсы:
                </Typography>
                <CustomButton style={{width: "fit-content"}} label="Создать инвойсы для КЗ" onChange={() => handleInvoiceRequest(createInvoicesOnlyKz)}/>
                <CustomButton style={{width: "fit-content"}} label="Создать инвойсы для НЕ КЗ" onChange={() => handleInvoiceRequest(createInvoicesOnlyNotKz)}/>
                <CustomButton style={{width: "fit-content"}} label="Создать инвойсы для ВСЕХ" onChange={() => handleInvoiceRequest(createInvoicesAll)}/>
                <Typography sx={{fontSize: "1.25rem", fontWeight: "500"}}>
                    Выгрузка данных:
                </Typography>
                { systemList.length > 0 &&
                    <>
                        <SelectField errors={errors?.current} label="Выбрать систему" value={selectedSystems.current}
                            onChange={e => handleChangeSystem("current", e.target.value)} options={systemList}
                        />
                        <CustomButton style={{width: "fit-content"}} label="Выгрузить данные за текущий месяц" onChange={() => handleDataRequest("current")}/>
                        <SelectField errors={errors?.last} label="Выбрать систему" value={selectedSystems.last} onChange={e => handleChangeSystem("last", e.target.value)} options={systemList} />
                        <CustomButton style={{width: "fit-content"}} label="Выгрузить данные за прошлый месяц" onChange={() => handleDataRequest("last")}/>
                    </>
                }
                <Typography sx={{fontSize: "1.25rem", fontWeight: "500"}}>
                    Привязку площадок к бонусным аккаунтам:
                </Typography>
                <CustomButton style={{width: "fit-content"}} label="Проверить привязку площадок к бонусным аккаунтам" onChange={() => handleInvoiceRequest(checkSystemConnectionWithBonusAcc)}/>
            </Box>
        </ContentContainer>
    )
}

export default Functions;